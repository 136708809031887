import React from "react";
import About2 from "../Components/about/About2";

function AboutPage() {
  return (
    <>
      <About2 />
    </>
  );
}

export default AboutPage;
